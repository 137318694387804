broadcast:
  ct:
    editor: Editor*in | Editor*innen
catalog:
  ct:
    mood: Stimmung | Stimmungen
    media: Track | Tracks
    artist: Künstler*in | Künstler*innen
    release: Album | Alben
    playlist: Show | Shows
    numMedia: 1 Track | {count} Tracks
  list:
    playAllTracks: Alle Tracks spielen | Alle {count} Tracks spielen
menu:
  accountSettings: Konto Einstellungen
  admin: Admin
  userVouchers: Gutschein Codes
  home: Radio
#  discover: Discover
  discover: Erkunden
  collection: Favoriten
  program: Programm
  reception: Empfang
  shop: Shop
  faq: FAQ
  about: Über uns
  contact: Kontakt
  donate: Spenden
  developers: Entwickler*innen
  imprint: Impressum
  dpp: Datenschutzerklärung
  terms: Nutzungsbedingungen
  login: Anmelden
  logout: Abmelden
navigation:
  search:
    title: Suche
    noResults: Sorry! Keine Einträge gefunden.
    cta: Durchsuche das open broadcast Archiv.
    info: Du kannst nach Tracks, Releases oder Namen von Künstler*innen suchen.
search:
  search: Suche
program:
  timeInFuture:
    lead: Delorean error.
    cta: Back to {0}!
    ctaText: now
text:
  duration: Dauer
  airtime: Airtime
datetime:
  today: heute
player:
  enqueueNext: Als nächstes spielen
  enqueueEnd: Ans Ende der Warteschlange
  favoritesAdd: Zu meinen Favoriten
  favoritesRemove: Aus Favoriten entfernen
  bannedAdd: Mag ich nicht
  bannedRemove: Mag ich doch
  share: Teilen
rating:
  downvoteTitle: Was stört dich?
  downvoteLead: Um das Radioprogramm ständig zu verbessern benötigen wir dein Feedback!
  downvoteScope:
    track: Ich mag diesen Titel nicht
    genre: Ich mag diese Art von Musik nicht
    emission: Ich mag diese Sendung nicht
    daytime: Der Track oder die Sendung passen nicht zur Tageszeit
    repetition: Ich habe diesen Track schon zu oft gehört
  downvoteComment: Kommentar
  downvoteThankYou: Vielen Dank für dein feedback!
pullToRefresh:
  pull: Pull down to refresh
  release: Release to refresh
  refreshing: Refreshing
loading:
  noResults:
    lead: Keine Resultate.
    cta: Please refine or {0} your search.
    ctaText: clear
  noFavorites:
    lead: Du hast noch keine Favoriten.
    cta: Go ahead and {0} some!
    ctaText: discover
formActions:
  save: Speichern
  send: Senden
account:
  title: Konto
  auth:
    login: Anmelden
    register: Konto erstellen
    password: Passwort
    sendToken: Code senden
    socialAccount: Mit einem bestehenden Konto
    usingEmail: Mit deiner E-Mail Adresse
    forgotPassword: Passwort vergessen?
    resetPassword: Login mit E-Mail-Bestätigung
    accountExists: Konto bereits vorhanden. Klicke auf "Code senden"
    loginToken: Login-Code
    loginEmailSent:
      title: Eine E-Mail mit einem Login-Code wurde an {0} geschickt.
      note: Falls du keine Nachricht erhalten hast, prüfe deinen Spam-Ordner.
    loginEmailSendAgain: E-Mail erneut senden
    social:
      continueWith: Weiter mit {0}
  legal:
    terms:
      term: Mit deiner Anmeldung stimmst du den {0} und der {1} zu.
      tos: Nutzungsbedingungen
      pp: Datenschutzerklärung
    availability:
      note: "{0}Derzeit können wir unseren On-Demand-Service nur für Menschen mit Wohnsitz in der Schweiz zugänglich machen.
            Mehr dazu unter {1}."
      faq: FAQ
  qrcode:
    title: Mobile Login
    info: Scanne den QR-Code mit deinem Mobiltelefon
  serviceInfoAside:
    title: Get eclectic!
    bullet1: Streaming in bester Qualität
    bullet2: Immer informiert
    bullet3: Höre unseren ganzen Katalog – wann und wo{0} du willst
  settings:
    subscription:
      title: Guthaben für kostenpflichtige Inhalte
    stream:
      title: Streaming Qualität / Umweltbelastung
      infoLong: "Per \"default\" liefern wir dir unseren Content in der bestmöglichen Qualität.\n
                 Die Übertragungsrate wird laufend an die Qualität deiner Internetverbindung angepasst."
      infoShort: "Zur Minimierung deines Datenvolumens / der Umweltbelastung kannst du eine maximale
                  Übertragungsrate festlegen:"
    email:
      title: E-Mail
      labelEmail: E-Mail Adresse
    password:
      title: Passwort
      labelPassword: Neues Passwort
      placeholderPassword: Passwort
      labelPasswordConfirm: Passwort bestătigen
      placeholderPasswordConfirm: Passwort
    address:
      title: Adresse
      labelLine1: Adresse
      labelLine2: Zusatz
      labelPostalCode: PLZ
      labelCity: Ort
      labelCountry: Land
    personal:
      title: Persönliche Angaben
      labelName: Name
      labelSurname: Nachname
      labelYearOfBirth: Jahrgang
      labelFavoriteVenue: Lieblingsclub
      gender:
        label: Pronomen
        options:
          female: sie/ihr
          male: er/sein
          other: they/their
    newsletter:
      title: Newsletter
    social:
      title: Verbundene Konten
      info: Verbundene Logins  # TODO: Text needed
      connect: verbinden
      disconnect: trennen
      loginAccounts:
        title: Login-Konten
      streamingAccounts:
        title: Streaming-Konten
        info: Verbundene streaming-Konten
subscription:
  credits: Guthaben
  # temporary
  noCredits: Kein Guthaben
  noCreditsNote: Aus rechtlichen Gründen ist im Moment kein Kauf von Guthaben möglich.
  #
  creditsExpired: Guthaben abgelaufen
  numDaysRemaining: 1 Tag | {count} Tage
  creditsUntilDate: Guthaben bis am {0}
  validNumDays: 1 Tag | {count} Tage
  validUntilDate: Gültig bis am {0}
  numUsedAndTotal: "eingelöst: {0}/{1}"
  voucher:
    code: Gratis-Code
    invalidCode: Ungültiger Gutschein-Code
    redeem: Jetzt Einlösen
    authenticateAndRedeem: Anmelden und einlösen
  subscribe:
    title: Guthaben
    lead: "Keine Abos - dafür Prepaid-Guthaben!\n
           Die von uns gespielten Inhalte jederzeit hören für {0} pro Monat."
    ctaVoucher:
      text: Hast du einen {0}?
      button: Gratis-Code
    ctaBuy:
      text: Du hast keinen Code? {0}
      button: Guthaben kaufen.
    # SubscribePlan.vue
    legalRestrictionInfo: |
        Momentan kannst du dich nicht für unseren On-Demand-Service anmelden.
        Wir warten auf rechtliche Klärungen damit wir vollen Zugang anbieten können.
    total:
      legal: Total inkl. aller Steuern und Gebühren
    payNow: Jetzt Bezahlen
    # SubscribeVoucher.vue
    redeemNow: Jetzt Jetzt Einlösen
geolocation:
  availability:
    note: Currently we can only provide access to our on-demand service for people living in Switzerland.
    faqNote: More about in the {0} section.
    faqLabel: FAQ
browserCompatibility:
  notSupported:
    note: Es scheint, dass dein Browser ({0}) nicht offiziell unterstützt wird.
    faqNote: Informationen dazu unter {0}
    faqLabel: FAQ
pages:
  pageNotFound: Seite nicht gefunden