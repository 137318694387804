broadcast:
  ct:
    editor: Editor | Editors
catalog:
  ct:
    mood: Mood | Moods
    media: Track | Tracks
    artist: Artist | Artists
    release: Album | Albums
    playlist: Show | Shows
    numMedia: 1 Track | {count} Tracks
  list:
#    playAllTracks: Track spielen | Alle {count} Tracks spielen
    playAllTracks: Play all tracks | Play all {count} tracks
menu:
  accountSettings: Account Settings
  admin: Admin
  userVouchers: Coupon Codes
  home: Radio
  discover: Discover
  collection: Favourites
  program: Programme
  reception: Reception
  shop: Shop
  faq: FAQ
  about: About
  contact: Contact
  donate: Donate
  developers: Developers
  imprint: Imprint
  dpp: Privacy Policy
  terms: Terms & Conditions
  login: Sign in
  logout: Sign out
navigation:
  search:
    title: Search
    noResults: Sorry! No entries found.
    cta: Search the open broadcast archive.
    info: You can search by track, release or artist name.
search:
  search: Search
program:
  timeInFuture:
    lead: Delorean error.
    cta: Back to {0}!
    ctaText: now
text:
  duration: Duration
  airtime: Airtime
datetime:
  today: Today
player:
  enqueueNext: Play next
  enqueueEnd: At the end of the queue
  favoritesAdd: To my favourites
  favoritesRemove: Remove from favorites
  bannedAdd: I do not like it
  bannedRemove: I like it
  share: Share
rating:
  downvoteTitle: What bothers you?
  downvoteLead: In order to constantly improve the radio programme, we need your feedback!
  downvoteScope:
    track: I don't like this title
    genre: I don't like this kind of music
    emission: I don't like this show
    daytime: The track or show does not match the time of day
    repetition: I've heard this track too many times
  downvoteComment: Comment
  downvoteThankYou: Thank you for your feedback!
pullToRefresh:
  pull: Pull down to refresh
  release: Release to refresh
  refreshing: refreshing
loading:
  noResults:
    lead: No results.
    cta: Please refine or {0} your search.
    ctaText: clear
  noFavorites:
    lead: You don't have any favorites yet.
    cta: Go ahead and {0} some!
    ctaText: discover
formActions:
  save: Save
  send: Send
account:
  title: Account
  auth:
    login: Sign in
    register: Create account
    password: Password
    sendToken: Send code
    socialAccount: With an existing account
    usingEmail: With your email address
    forgotPassword: Forgot Password?
    resetPassword: Login with email confirmation
    accountExists: Account already exists. Click on "Send code"
    loginToken: Login code
    loginEmailSent:
      title: An email with a login code was sent to {0}.
      note: If you haven't received a message, check your spam folder.
    loginEmailSendAgain: Resend email
    social:
      continueWith: Continue with {0}
  legal:
    terms:
      term: By signing up you agree to the {0} and {1}.
      tos: Terms of Service
      pp: Privacy Policy
    availability:
      note: "{0} Currently we can only provide access to our on-demand service for people living in Switzerland. More about in the {1} section."
      faq: FAQ
  qrcode:
    title: Mobile login
    info: Scan the QR code with your mobile phone
  serviceInfoAside:
    title: Get eclectic!
    bullet1: High-Quality Streaming
    bullet2: Always up to date
    bullet3: Listen to the whole catalog when & where{0} you want
  settings:
    subscription:
      title: Credit for Paid Content
    stream:
      title: Streaming quality / environmental impact
      infoLong: "By \"default\" we deliver our content in the best possible quality.\n The transfer rate is continuously adjusted to the quality of your internet connection."
      infoShort: "To minimize your data volume / environmental impact, you can set a maximum transfer rate:"
    email:
      title: E-mail
      labelEmail: E-mail address
    password:
      title: Password
      labelPassword: New Password
      placeholderPassword: Password
      labelPasswordConfirm: Confirm Password
      placeholderPasswordConfirm: Password
    address:
      title: address
      labelLine1: address
      labelLine2: additive
      labelPostalCode: Postcode
      labelCity: location
      labelCountry: country
    personal:
      title: Personal Information
      labelName: Name
      labelSurname: Surname
      labelYearOfBirth: Year of birth
      labelFavoriteVenue: Favorite club or venue
      gender:
        label: Pronoun
        options:
          female: she/her
          male: he/his
          other: they/them
    newsletter:
      title: Newsletter
    social:
      title: Connected accounts
      info: Connected login accounts  # TODO: Text needed
      connect: Connect
      disconnect: Disconnect
      loginAccounts:
        title: Login accounts
      streamingAccounts:
        title: Streaming accounts
        info: Connected streaming accounts  # TODO: Text needed
subscription:
  credits: Credits
  # temporary
  noCredits: No Credits
  noCreditsNote: For legal reasons it is currently not possible to buy credits.
  #
  creditsExpired: Credit expired
  numDaysRemaining: 1 day | {count} days
  creditsUntilDate: Balance until {0}
  validNumDays: 1 day | {count} days
  validUntilDate: Valid until {0}
  numUsedAndTotal: "redeemed: {0}/{1}"
  voucher:
    code: Voucher code
    invalidCode: Invalid Coupon Code
    redeem: Redeem now
    authenticateAndRedeem: Sign up and redeem
  subscribe:
    title: Credits
    lead: "No subscriptions - but prepaid credit!\n Listen to the content we play anytime for {0} per month."
    ctaVoucher:
      text: Do you have a {0}?
      button: Voucher code
    ctaBuy:
      text: Don't have a code? {0}
      button: Buy credits.
    # SubscribePlan.vue
    legalRestrictionInfo: |
        We're sorry to say that you can't subscribe to our on-demand service at the moment.
        We're waiting for some legal clarification before we can offer full access.
    total:
      legal: Total including all taxes and fees
    payNow: Pay now
    # SubscribeVoucher.vue
    redeemNow: Redeem Now
geolocation:
  availability:
    note: Currently we can only provide access to our on-demand service for people living in Switzerland.
    faqNote: More about in the {0} section.
    faqLabel: FAQ
browserCompatibility:
  notSupported:
    note: It looks like your browser ({0}) is not officially supported.
    faqNote: Read more in our {0} section.
    faqLabel: FAQ
pages:
  pageNotFound: Page not found
